
import { Component, Vue } from 'vue-property-decorator';
import { ValidationRules } from '../models/validationRules';
import { UserServiceClient } from '@/api/clients/userServiceClient';
import Notification from '@/components/notifications/notification.vue';
import { NotificationType } from '@/types/notificationType';

const userServiceClient = new UserServiceClient();

@Component({
  components: { Notification }
})
export default class ResetPassword extends Vue {
  public rules: ValidationRules = new ValidationRules();
  public newPassword: string = '';
  public confirmPassword: string = '';
  public success: boolean = false;

  private get notificationInstance(): NotificationType {
    return this.$refs.notification as NotificationType;
  }

  public get passwordConfirmationRule() {
    return () =>
      this.newPassword === this.confirmPassword || 'Passwords must match';
  }

  public checkQuerystringParams() {
    return (
      this.$route.query.username !== '' && this.$route.query.resetToken !== ''
    );
  }

  public async formSubmit(form: VForm) {
    this.notificationInstance.hide();

    try {
      if (form.validate() && this.checkQuerystringParams()) {
        await userServiceClient.changePassword({
          username: this.$route.query.username.toString(),
          newPassword: this.newPassword,
          token: this.$route.query.resetToken.toString()
        });

        this.success = true;
      }
    } catch (err) {
      console.error(err.response);

      this.notificationInstance.showError(
        'There was an error trying to reset your password.'
      );
    }
  }
}
